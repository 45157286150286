import React, { useEffect, useState } from "react";
import Seo from "../../../components/Seo/seo";
import Layout from "../../../components/Layout/layout";

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import "./styles.scss";
import { CONVEX_API_URL } from "../../../../config/config";
import moment from "moment";
import { LinearProgress } from "@mui/material";

const JoinSession = (props) => {

  const leadID = props?.params?.id ?? null;
  const [sessionDetails, setSessionDetails] = useState(null);
  const [alertState, setAlert] = useState({
    message: "",
    isOpen: false,
  });
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if(!leadID) {
      setAlert({
        message: "Invalid session URL!",
        isOpen: true
      });

      return;
    }

    fetchSessionDetails();
  }, []);
  
  const fetchSessionDetails = async () => {
    try {
      if(!leadID)
        return;
      setLoading(true);
      const url = `${CONVEX_API_URL}/program/null/campaign/null/events/null/lead/${leadID}`;
      
      const res = await fetch(url);
      const data = await res.json();

      console.log(res, data);

      if(res.status!==200) {
        throw data?.message ?? "Soemthing went wrong!";
      }

      setSessionDetails(data);     
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      setAlert({
        message: "Couldn't fetch session details! Please try again later.",
        isOpen: true
      });
    }
  };

  const markAttendance = async() => {
    try {
      if(!leadID){
        setAlert({
          message: "Invalid session URL! Please enter the correct URL",
          isOpen: true
        });

        return;
      }

      if(!sessionDetails?.start_date_time) {
        throw "session details missing!";
      }

      const validTime = moment(sessionDetails.start_date_time).subtract(15, "minutes");

      if(moment().isBefore(validTime)) {
        setAlert({
          message: "You can join only 15 minutes prior to session start time, Please try again later!",
          isOpen: true
        });

        return;
      }

      setLoading(true);
      const url = `${CONVEX_API_URL}/program/null/campaign/null/events/null/lead/${leadID}/mark-attendance`;
      
      const res = await fetch(url);
      const data = await res.json();

      if(res.status!==200 || !data?.meeting_url) {
        throw data?.message ?? "Something went wrong!";
      }

      setLoading(false);

      if(typeof window !== "undefined") {
        window.open(data.meeting_url, "_blank");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      setAlert({
        message: "Couldn't fetch session details! Please try again later.",
        isOpen: true
      });
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlert({
      message: "",
      isOpen: false
    });
  };

  return (
    <>
      <Seo
        title=""
        description=""
        url="https://www.kraftshala.com"
      />
      <Layout formLink="/">
        {
          isLoading && <LinearProgress />
        }
        <div className="ks-live-join-session-main">
          <div className="hero-section">
            <h1>Kraftshala LIVE Session</h1>
            <div className="session-info">
              <div className="details">
                <div>Session:</div>
                <div>{sessionDetails?.title ?? "--"}</div>
                <div>Speaker:</div>
                <div>{sessionDetails?.speakers ?? "--"}</div>
                <div>Email:</div>
                <div>{sessionDetails?.email}</div>
                <div>Start Time:</div>
                <div>{sessionDetails?.start_date_time ? moment(sessionDetails?.start_date_time).format("ll | LT") : "--"}</div>
              </div>
              <button disabled={isLoading} className="join-session-btn" onClick={markAttendance}>
                {isLoading ? "Loading...": "Join Session"}
              </button>
            </div>
          </div>
        </div>
      </Layout>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={alertState.isOpen} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error" sx={{ width: '100%' }}>
          {alertState.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default JoinSession;